<template>
  <div class="content">
    <div class="create">
      <div class="row">
        <div class="col-4">
          <input type="text" class="form-control" placeholder="Title" v-model="title" />
        </div>
        <div class="col-4">
          <input type="text" class="form-control" placeholder="slug" v-model="slug" disabled />
        </div>
        <div class="col-4">
          <button class="vave-btn btn-blue" @click="createNewPage()" :disabled="!this.title">Add page</button>
        </div>
      </div>
    </div>
    <div class="table-container">
      <table class="table align-middle">
        <thead class="head">
          <tr>
            <th class="ps-4">Id</th>
            <th>Title</th>
            <th>Slug</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="page in pagesList" :key="page.id">
            <td class="ps-4">{{page.id}}</td>
            <td>{{page.title}}</td>
            <td class="openpage">
              <a target="_blank" class="vave-btn" :href="pwaUrl + '/' + page.slug">
                <i class="fa-regular fa-up-right-from-square"></i>
                <span class="ms-2">{{page.slug}}</span>
              </a>
            </td>
            <td>
              <select v-model="page.status" @change="updateStatus(page)" class="form-select">
                <option value="published">published</option>
                <option value="draft">draft</option>
              </select>
            </td>
            <td>
              <router-link class="w-full vave-btn" :to="'/pages/' + page.id">
                <i class="fa-regular fa-pen-to-square me-1"></i>
                Edit
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "./../http.js";

export default {
  data() {
    return {
      title: "",
      pagesList: [],
      pwaUrl: process.env.VUE_APP_PWAURL,
    };
  },
  computed: {
    slug() {
      return this.title.toLowerCase().replace(/ /g, "-");
    },
  },
  mounted() {
    this.retrievePages();
  },
  methods: {
    retrievePages() {
      if (this.pagesList.length <= 0) {
        this.getPages();
      }
    },
    getPages() {
      axios.get("/api/admin/pages").then((response) => {
        if (response.data.data) {
          this.pagesList = response.data.data;
        }
      });
    },
    createNewPage() {
      if (
        !confirm(
          'do you want to create a new page with title "' +
            this.title +
            '" and slug "' +
            this.slug +
            '"?'
        )
      ) {
        return;
      }
      axios
        .post("/api/admin/pages", {
          title: this.title,
          slug: this.slug,
          parent: 0,
          status: "draft",
        })
        .then((response) => {
          this.title = "";
          if (response.data.data) {
            this.getPages();
          }
        });
    },
    updateStatus(page) {
      let message = "This page will be visible to the public";
      if (page.status == "draft") {
        message = "This page won't be visible to the public anymore";
      }

      if (!confirm("Are you sure you want to update the status? " + message)) {
        page.status = page.status == "draft" ? "published" : "draft";
        return;
      }

      axios
        .put("/api/admin/pages/" + page.id, {
          status: page.status,
        })
        .then(
          (response) => {
            if (response.status == 200) {
              this.$toast.success("Status updated", {
                hideProgressBar: true,
              });
            }
          },
          () => {
            this.$toast.error("Something went wrong", {
              hideProgressBar: true,
            });
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped>
tbody tr {
  &:hover {
    background: #fafafa;
  }
}
.create {
  padding-bottom: 20px;
}
.openpage {
  a {
    color: #212529;
    text-decoration: none;
    &:hover {
      color: #212529;
      text-decoration: none;
    }
  }
}
</style>